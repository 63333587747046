import { ElementType, forwardRef, HTMLAttributes, useMemo } from 'react'
import classNames from 'classnames'
import parse, { domToReact } from 'html-react-parser'

import { nl2br, removeParagraph } from '@/utils/strings'

export const TypographyVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle',
  'body',
  'small',
  'caption',
  'overline',
  'button'
] as const

export type TypographyVariant = (typeof TypographyVariants)[number]

export type TypographyElement =
  | HTMLHeadingElement
  | HTMLParagraphElement
  | HTMLSpanElement

export type TypographyProps = HTMLAttributes<TypographyElement> &
  Partial<{
    component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
    paragraph: boolean
    strong: boolean
    variant: TypographyVariant
  }>

const options = {
  replace: (domNode: any) => {
    if (domNode.type === 'tag' && ['em', 'strong'].includes(domNode.name)) {
      const Tag = domNode.name
      return (
        <>
          {domNode.children.map((child: any, index: number) => {
            if (child.type === 'text') {
              return child.data
                .split(' ')
                .map((word: string, wordIndex: number) => (
                  <Tag key={`${index}-${wordIndex}`}>
                    {word}
                    {wordIndex < child.data.split(' ').length - 1 ? ' ' : ''}
                  </Tag>
                ))
            }
            return domToReact(child, options)
          })}
        </>
      )
    }
  }
}

const Typography = forwardRef<TypographyElement, TypographyProps>(
  (
    {
      variant = 'body',
      component,
      paragraph,
      className,
      children,
      strong,
      ...props
    },
    ref
  ) => {
    const variantClasses = useMemo(
      () => ({
        h1: 'font-inter text-s-h1 lg:text-d-h1 -my-[.35em] lg:-my-[.25em] hyphens-manual [&_em]:not-italic',
        h2: 'font-inter text-s-h2 lg:text-d-h2 -my-[.25em] lg:-my-[.20em] hyphens-manual [&_em]:not-italic',
        h3: 'font-inter text-s-h3 lg:text-d-h3 -my-[.25em] hyphens-manual [&_em]:not-italic',
        h4: 'font-inter text-s-h4 lg:text-d-h4 -my-[.25em] hyphens-manual [&_em]:not-italic',
        h5: 'font-inter text-s-h5 lg:text-d-h5 -my-[.25em] hyphens-manual [&_em]:not-italic',
        h6: 'font-inter text-s-h6 lg:text-d-h6 -my-[.35em] lg:-my-[.25em] hyphens-manual [&_em]:not-italic',
        subtitle: classNames('font-roboto text-subtitle -my-[.4em]', {
          'font-medium': strong
        }),
        body: classNames('font-roboto text-body -my-[.4em]', {
          'font-medium': strong
        }),
        small: classNames('font-roboto text-small -my-[.4em]', {
          'font-medium': strong
        }),
        caption: classNames('font-roboto text-caption -my-[.55em]', {
          'font-medium': strong
        }),
        overline: 'font-roboto text-overline uppercase -my-[.5em]',
        button: classNames('font-roboto text-button -my-[.45em]', {
          'font-medium': strong
        })
      }),
      [strong]
    )

    const innerClasses = classNames(
      '[&_ul]:list-disc [&_ul]:pl-[1.45rem] [&_ul>br]:hidden',
      '[&_ol]:list-decimal [&_ol]:pl-[1.65rem]',
      '[&_a]:pointer:hover:opacity-70 [&_a]:transition-opacity [&_a]:duration-300 [&_a]:ease-out-cubic',
      '[&_strong]:font-medium',
      '[&_b]:font-medium'
    )

    const Component = (component || (paragraph ? 'p' : 'span')) as ElementType

    return (
      <Component
        ref={ref}
        className={classNames(
          (variantClasses as any)[variant],
          innerClasses,
          className
        )}
        {...props}
      >
        {parse(
          children ? removeParagraph(nl2br(children as string)) : '',
          options
        )}
      </Component>
    )
  }
)

export default Typography
