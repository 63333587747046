import { HTMLAttributes } from 'react'

import SVGIcon from '@/components/base/SVGIcon'

type ToTopProps = HTMLAttributes<HTMLDivElement>

const ToTop = (props: ToTopProps) => {
  return (
    <div
      className="z-totop fixed bottom-[2rem] left-[2rem] md:bottom-[4rem] md:left-[4rem] md:hidden lg:left-[6rem] xl:left-[10rem]"
      {...props}
    >
      <button
        className="flex h-[6rem] w-[6rem] items-center justify-center rounded-full bg-white shadow-3xl"
        aria-label="Back to top"
      >
        <SVGIcon symbol="arrow-up" className="h-[2rem] w-[1.6rem] fill-pink" />
      </button>
    </div>
  )
}

export default ToTop
