import { merge } from 'lodash'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type State = Partial<{
  header: {
    theme: 'light' | 'dark'
  }
  page: {
    background: string
  }
}>

type Actions = {
  // eslint-disable-next-line no-unused-vars
  setCurrentLayout: (state?: State) => void
}

const initialState: State = {
  header: undefined,
  page: undefined
}

const useLayoutStore = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      setCurrentLayout: (state) => set((prevState) => merge(prevState, state))
    }))
  )
)

export { useLayoutStore }
