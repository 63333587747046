import { forwardRef, Fragment, useCallback, useRef, useState } from 'react'
import { TransitionStatus } from 'react-transition-group'
import { useIsomorphicLayoutEffect } from 'react-use'
import classNames from 'classnames'
import gsap from 'gsap'
import { default as LenisImpl } from 'lenis'
import Link from 'next/link'
import { useRouter } from 'next/router'

import LangSelector from '@/components/base/LangSelector'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { stripSlash } from '@/utils/path'

import { Lenis, useScrollbar } from '@/contexts/lenis'
import { useStore } from '@/contexts/store'
import { WPMenu } from '@/types'

type MenuProps = {
  state: TransitionStatus
  onClose?: () => void
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ state, onClose }, ref) => {
    const { menu, strings } = useStore()
    const { asPath, locale } = useRouter()
    const [lenis, setLenis] = useState<Lenis>()
    const [track, thumb, needsScrollbar] = useScrollbar(lenis)
    const wrapper = useRef<HTMLDivElement>(null!)
    const content = useRef<HTMLDivElement>(null!)
    const container = useRef<HTMLDivElement>(null!)

    const isActive = useCallback(
      (url: string) => stripSlash(url) === stripSlash(`/${locale}${asPath}`),
      [asPath, locale]
    )

    const renderTitle = (title?: string, className?: string) => {
      return (
        <>
          <SVGIcon
            symbol="menu-item-icon"
            className={classNames('h-[1.4rem] w-[1.3rem]', className)}
          />
          <Typography variant="subtitle" className="text-white">
            {title}
          </Typography>
        </>
      )
    }

    const renderNav = (menu?: WPMenu['menu']) => {
      return (
        <nav className="col-span-1 flex flex-col space-y-xs">
          {menu?.map(({ title, url, classes, children }, index) => (
            <Fragment key={`menu-${index}`}>
              {children?.length > 0 ? (
                <div className="flex items-center space-x-[.4rem] py-[.4rem]">
                  {renderTitle(
                    title,
                    classNames({
                      'fill-blue-light': classes !== 'academy',
                      'fill-green': classes === 'academy'
                    })
                  )}
                </div>
              ) : classes === 'auth' ? (
                <a
                  href={url}
                  target="_blank"
                  className="flex items-center space-x-[.8rem] py-[.4rem]"
                >
                  <SVGIcon
                    symbol="user-icon"
                    className={'h-[1.6rem] w-[1.6rem] fill-white'}
                  />
                  <Typography variant="subtitle" className="text-white">
                    {title}
                  </Typography>
                </a>
              ) : classes === 'engage' ? (
                <a
                  href={url}
                  target="_blank"
                  className="!mt-md flex px-[1.6rem] py-[.4rem]"
                  aria-label={title}
                >
                  <SVGIcon
                    symbol="engage-logo"
                    className="h-[2rem] w-[8.5rem] fill-white"
                  />
                </a>
              ) : (
                <Link
                  href={url}
                  scroll={false}
                  className="flex items-center space-x-[.4rem] py-[.4rem]"
                >
                  {renderTitle(title, 'fill-white opacity-30')}
                </Link>
              )}

              {children?.length > 0 ? (
                <ul className="flex flex-col">
                  {children?.map(({ title, url }, index) => (
                    <li key={`submenu-${index}`} className="pl-xs">
                      <Link
                        href={url}
                        scroll={false}
                        className="flex border-b-2 border-white/10 px-[.4rem] py-xs"
                      >
                        <Typography
                          variant="body"
                          className={classNames('fill-white', {
                            'text-white/70': !isActive(url),
                            'text-white': isActive(url)
                          })}
                        >
                          {title}
                        </Typography>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : null}
            </Fragment>
          ))}
        </nav>
      )
    }

    const renderEngageNav = (menu?: WPMenu['menu']) => {
      return menu ? (
        <nav className="col-span-1 flex flex-col pl-xs">
          <a
            href={menu[0].url}
            target="_blank"
            className="flex py-[.4rem]"
            aria-label={menu[0].title}
          >
            <SVGIcon
              symbol="engage-logo"
              className="h-[2rem] w-[8.5rem] fill-white"
            />
          </a>
        </nav>
      ) : null
    }

    useIsomorphicLayoutEffect(() => {
      const lenis = new LenisImpl({
        smoothWheel: true,
        wrapper: wrapper.current,
        content: content.current
      })

      const tick = (time: number) => {
        lenis.raf(time * 1000)
      }

      gsap.ticker.add(tick)

      setLenis(lenis as Lenis)

      return () => {
        gsap.ticker.remove(tick)
        lenis.destroy()
        setLenis(undefined)
      }
    }, [])

    return (
      <div
        ref={ref}
        className={classNames(
          'fixed bottom-0 left-0 right-0 top-0 z-menu'
          //'before:absolute before:left-0 before:top-0 before:h-full before:w-full before:bg-black/20',
        )}
      >
        <span
          className={classNames(
            'absolute left-0 top-0 h-full w-full bg-black/20',
            {
              'opacity-0': state === 'entering',
              'opacity-100 transition-opacity duration-500 ease-in-out-cubic':
                state === 'entered',
              'opacity-0 transition-opacity duration-500 ease-in-out-cubic':
                state === 'exiting'
            }
          )}
          onClick={onClose}
        />
        <div
          ref={container}
          className={classNames(
            'absolute right-0 top-0 w-full bg-blue text-white md:right-[2rem] md:top-[2rem] md:w-auto md:rounded-lg md:p-md md:pb-[0rem] md:pr-[4.8rem] md:pt-[10rem] lg:right-[4rem] xl:right-[8rem]',
            'after:absolute after:left-0 after:right-[2rem] after:top-0 after:h-[10rem] after:bg-blue md:after:hidden',
            {
              'translate-y-[2rem] opacity-0': state === 'entering',
              'translate-y-0 opacity-100 transition-all duration-500 ease-out-cubic':
                state === 'entered',
              '-translate-y-[2rem] opacity-0 transition-all duration-500 ease-in-out-cubic':
                state === 'exiting'
            },
            {
              'after:opacity-0': state === 'entering',
              'after:opacity-100 after:transition-opacity after:duration-500 after:ease-out-cubic':
                state === 'entered',
              'after:opacity-0 after:transition-opacity after:duration-500 after:ease-in-out-cubic':
                state === 'exiting'
            }
          )}
        >
          <div className="absolute right-[10rem] top-[5rem] z-10 flex -translate-y-1/2 items-center gap-xs sm:gap-gutter md:right-[12rem]">
            <div className="relative z-10 [&_a_span]:hidden [&_a_span]:sm:block">
              {renderNav(menu?.top_menu?.menu)}
            </div>
            {!strings?.menu?.hide_lang_selector ? (
              <div className="relative z-10">
                <LangSelector />
              </div>
            ) : null}
          </div>
          <div
            ref={wrapper}
            className="relative max-h-screen overflow-auto px-[2rem] pb-[8rem] pt-[12rem] md:max-h-[calc(100_*_var(--vh)_-_14rem)] md:p-0 md:pb-[3rem] md:pr-[10.5rem]"
          >
            <div
              ref={content}
              className="grid grid-cols-2 gap-x-[1rem] gap-y-[3rem] md:grid-cols-[20rem,_20rem] md:gap-x-lg md:gap-y-md"
            >
              {renderNav(menu?.main_menu_01?.menu)}
              {renderNav(menu?.main_menu_02?.menu)}
              {renderNav(menu?.main_menu_03?.menu)}
              {renderNav(menu?.main_menu_04?.menu)}
              {renderNav(menu?.main_menu_05?.menu)}
              {renderNav(menu?.main_menu_06?.menu)}
              {renderEngageNav(menu?.main_menu_07?.menu)}
            </div>
          </div>

          <div
            className={classNames(
              'group absolute bottom-[0rem] right-[5rem] top-[0rem] w-[1.2rem] translate-x-1/2 touch:hidden',
              {
                visible: needsScrollbar,
                invisible: !needsScrollbar
              }
            )}
          >
            <div
              ref={track}
              className="absolute left-[.3rem] top-1/2 h-1/3 w-[.6rem] -translate-y-1/2 rounded-full bg-white/15"
            >
              <div
                ref={thumb}
                className={classNames(
                  'absolute left-0 top-0 w-full',
                  'before:absolute before:bottom-0 before:left-0 before:top-0 before:w-full before:rounded-full before:bg-white/90'
                )}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default Menu
