import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { default as LenisImpl } from 'lenis'
import { keys } from 'lodash'

import ToTop from '@/components/base/ToTop'
import { isTouch } from '@/utils/device'
import { pageHasHeroSection } from '@/utils/page'

import Scrollbar from './components/Scrollbar'
import { State, useLenisStore } from './useLenisStore'
import { WPPageData } from '@/types'

export type Lenis = LenisImpl & Pick<State, 'dimensions'>

export const LenisContext = createContext<{ lenis?: Lenis } | undefined>(
  undefined
)

const LenisProvider = ({
  children,
  pageData
}: PropsWithChildren<{ pageData?: WPPageData }>) => {
  const [lenis, setLenis] = useState<Lenis>()
  const wrapper = useRef<HTMLDivElement>(null!)
  const content = useRef<HTMLDivElement>(null!)
  const hasHero = useMemo(
    () => pageHasHeroSection(pageData) || keys(pageData).length === 0,
    [pageData]
  )
  const setState = useLenisStore((state) => state.setState)

  const handleScrollToTop = useCallback(() => {
    if (!lenis) return
    lenis?.scrollTo(0, { duration: 1 })
  }, [lenis])

  const update = useCallback(
    ({ scroll, limit, progress, dimensions }: Lenis) => {
      ScrollTrigger.update()
      setState({ scroll, limit, progress, dimensions })
    },
    [setState]
  )

  useIsomorphicLayoutEffect(() => {
    const lenis = new LenisImpl({
      smoothWheel: true,
      eventsTarget: document.documentElement,
      ...(!isTouch && {
        wrapper: wrapper.current,
        content: content.current
      })
    })

    if (!isTouch) {
      wrapper.current.style.overflow = 'auto'
      ScrollTrigger.defaults({ scroller: wrapper.current })
      ScrollTrigger.scrollerProxy(wrapper.current, {
        scrollTop() {
          return lenis.scroll
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          }
        }
      })
    }

    setLenis(lenis as Lenis)

    return () => {
      lenis.destroy()
      setLenis(undefined)
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (!lenis) return

    const tick = (time: number) => {
      lenis.raf(time * 1000)
    }

    gsap.ticker.add(tick)

    lenis.on('scroll', update)

    return () => {
      lenis.off('scroll', update)
      gsap.ticker.remove(tick)
    }
  }, [lenis])

  useIsomorphicLayoutEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    if (!lenis) return
    lenis.scrollTo(0, { immediate: true })
  }, [lenis])

  const contextValue = useMemo(() => ({ lenis }), [lenis])

  return (
    <LenisContext.Provider value={contextValue}>
      <div ref={wrapper} className="static h-full w-full">
        <div
          ref={content}
          className={classNames('static h-auto w-full', {
            'mt-[8rem] md:mt-[10rem]': !hasHero
          })}
        >
          {children}
        </div>
      </div>
      <ToTop onClick={handleScrollToTop} />
      <Scrollbar />
    </LenisContext.Provider>
  )
}

export default LenisProvider
