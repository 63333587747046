import classNames from 'classnames'

import SVGIcon from '@/components/base/SVGIcon'

type LogoProps = {
  theme?: 'light' | 'dark'
  variant?: 'default' | 'compact'
}

const Logo = ({ variant = 'default', theme = 'light' }: LogoProps) => {
  return (
    <SVGIcon
      symbol="logo"
      className={classNames(
        'block',
        'transition-colors duration-300 ease-out-cubic',
        {
          'h-[3.8rem] w-[15.1rem]': variant === 'default',
          'h-[3.4rem] w-[12rem]': variant === 'compact'
        },
        {
          'fill-white': theme === 'light',
          'fill-blue': theme === 'dark'
        }
      )}
    />
  )
}

export default Logo
