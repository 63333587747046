import { HTMLAttributes } from 'react'
import classNames from 'classnames'

export type LoaderProps = HTMLAttributes<HTMLDivElement> & {
  variant?: 'small' | 'medium'
}

const Loader = ({ className, ...props }: LoaderProps) => {
  return (
    <span className={classNames('block', className)} {...props}>
      <svg
        viewBox="0 0 108 108"
        xmlns="http://www.w3.org/2000/svg"
        version="1"
        className="animate-rotate"
      >
        <defs>
          <linearGradient
            id="gradient"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#009FE3" offset="0" />
            <stop stopColor="#99CC33" offset="1" />
          </linearGradient>
        </defs>
        <circle
          cx="54"
          cy="54"
          r="50"
          stroke="url(#gradient)"
          strokeWidth="6"
          fill="none"
        />
      </svg>
    </span>
  )
}

export default Loader
