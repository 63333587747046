import { forwardRef, useMemo, useState } from 'react'
import classNames from 'classnames'
import NextImage, { ImageProps as NextImageProps } from 'next/image'

import AspectRatio, { AspectRatioProps } from '@/components/base/AspectRatio'

import { WPImage } from '@/types'

type ImageProps = Omit<NextImageProps, 'src' | 'alt'> &
  Partial<{
    source: WPImage
    size: 'thumbnail' | 'medium' | 'large'
    ratio: AspectRatioProps['ratio']
    fit: 'contain' | 'cover'
    alt: string
  }>

const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      source,
      alt,
      size = 'large',
      fit = 'cover',
      fill,
      className,
      ratio,
      ...props
    },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false)

    const src = useMemo(
      () => source?.sizes[size]?.url || source?.url || '',
      [source, size]
    )
    const width = useMemo(
      () => source?.sizes[size]?.width || source?.width || 0,
      [source, size]
    )
    const height = useMemo(
      () => source?.sizes[size]?.height || source?.height || 0,
      [source, size]
    )
    const hasRatio = useMemo(() => ratio !== undefined, [ratio])

    const renderImage = () => {
      return (
        <NextImage
          ref={ref}
          src={src}
          alt={alt || ''}
          fill={hasRatio || fill}
          onLoad={() => setLoaded(true)}
          className={classNames(
            'transition-opacity duration-300 ease-in-out-cubic',
            {
              'object-cover object-center': fit === 'cover',
              'object-contain object-center': fit === 'contain'
            },
            {
              'opacity-0': !loaded,
              'opacity-100': loaded
            },
            className
          )}
          {...(!hasRatio && !fill ? { width, height } : {})}
          {...props}
        />
      )
    }
    return ratio ? (
      <AspectRatio ratio={ratio}>{renderImage()}</AspectRatio>
    ) : (
      <>{renderImage()}</>
    )
  }
)

export default Image
