export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
export const FRONT_BASE_URL = process.env.NEXT_PUBLIC_FRONT_BASE_URL
export const LANGUAGES = JSON.parse(process.env.NEXT_PUBLIC_LANGUAGES!)

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string

export const KEY = {
  GF_CONSUMER_KEY: process.env.NEXT_PUBLIC_GF_CONSUMER_KEY,
  GF_CONSUMER_SECRET: process.env.NEXT_PUBLIC_GF_CONSUMER_SECRET
}

export const API = {
  BASE: '/wp-json/wp/v2',
  ROUTES: '/wp-json/gusto/routes',
  STRINGS: '/wp-json/gusto/options',
  MENU_LOCATIONS: '/wp-json/gusto/nav/menu-locations',
  GRAVITY_FORM: '/wp-json/gf/v2/forms',
  YOAST: '/wp-json/yoast/v1/presenters'
}

export const MediaQuery = {
  SMALL: '(max-width: 1023px)',
  LARGE: '(min-width: 1024px)',
  TOUCH: '(hover: none) and (pointer: coarse)',
  POINTER: '(hover: hover) and (pointer: fine)'
}
