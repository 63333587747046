import { useCallback, useRef } from 'react'
import { useEvent, useIsomorphicLayoutEffect } from 'react-use'
import { Inter, Roboto_Condensed } from 'next/font/google'

const inter = Inter({ weight: ['400', '700'], subsets: ['latin'] })

const roboto = Roboto_Condensed({ weight: ['400', '500'], subsets: ['latin'] })

type ThemeProps = { fluid?: boolean }

const Theme = ({ fluid }: ThemeProps) => {
  const lastViewportWidth = useRef(0)

  const resize = useCallback(() => {
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    if (lastViewportWidth.current === viewportWidth) return
    lastViewportWidth.current = viewportWidth

    const vh = (viewportHeight * 0.01).toFixed(2)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  useIsomorphicLayoutEffect(() => resize(), [resize])

  useEvent('resize', resize)

  return (
    <style jsx global>{`
      :root {
        --font-family-inter: ${inter.style.fontFamily};
        --font-family-roboto: ${roboto.style.fontFamily};

        --font-size: ${fluid ? `max(${(10 / 1440) * 100}vw, 10px)` : '10px'};

        --gutter: 1rem;
      }

      @media (min-width: 768px) {
        :root {
          --gutter: 3rem;
        }
      }
    `}</style>
  )
}

export default Theme
