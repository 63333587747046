import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { useRouter } from 'next/router'

import { useLoaderStore } from '@/store/loader'
import { useWebGLStore } from '@/webgl/store/loader'

import MaskTransition, {
  MaskTransitionHandler
} from './components/MaskTransition'
import {
  TRANSITION_ENTER_DURATION,
  TRANSITION_LEAVE_DURATION
} from './constants'

export const TransitionContext = createContext<undefined>(undefined)

const TransitionProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const nodeRef = useRef<HTMLElement>(null!)
  const maskRef = useRef<MaskTransitionHandler>(null!)
  const setLoaderState = useLoaderStore((state) => state.setState)

  const { contextSafe } = useGSAP({ scope: nodeRef })

  const handleEnterTransition = contextSafe(() =>
    requestAnimationFrame(() => {
      if (
        !useLoaderStore.getState().pageLoaded ||
        !useWebGLStore.getState().compiled
      )
        return
      gsap.timeline().add(maskRef.current?.leaveTimeline())
    })
  )

  const handleLeaveTransition = contextSafe(() => {
    setLoaderState({ pageLoaded: false })
    gsap.timeline().add(maskRef.current?.enterTimeline())
  })

  const handleStateChange = useCallback(() => {
    if (
      useLoaderStore.getState().pageLoaded &&
      useWebGLStore.getState().compiled
    ) {
      handleEnterTransition()
    }
  }, [handleEnterTransition])

  useEffect(() => {
    const unsubscribe = useLoaderStore.subscribe(
      (state) => state.pageLoaded,
      () => handleStateChange()
    )

    return () => {
      unsubscribe()
    }
  }, [handleStateChange])

  useEffect(() => {
    const unsubscribe = useWebGLStore.subscribe(
      (state) => state.compiled,
      () => handleStateChange()
    )

    return () => {
      unsubscribe()
    }
  }, [handleStateChange])

  const contextValue = useMemo(() => undefined, [])

  return (
    <TransitionContext.Provider value={contextValue}>
      <SwitchTransition mode="out-in">
        <Transition
          appear
          key={router.asPath}
          nodeRef={nodeRef}
          timeout={{
            enter: TRANSITION_ENTER_DURATION * 1000,
            exit: TRANSITION_LEAVE_DURATION * 1000
          }}
          onEnter={handleEnterTransition}
          onExit={handleLeaveTransition}
          unmountOnExit
        >
          <main ref={nodeRef} className="static h-full w-full">
            {children}
            <MaskTransition ref={maskRef} />
          </main>
        </Transition>
      </SwitchTransition>
    </TransitionContext.Provider>
  )
}

export default TransitionProvider
