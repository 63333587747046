import { createContext, PropsWithChildren, useRef } from 'react'
import { createStore, StoreApi } from 'zustand'
import { devtools } from 'zustand/middleware'

import { WPMenu } from '@/types'

export type Store = {
  strings?: Record<string, any>
  yoast?: string
  menu?: {
    top_menu: WPMenu
    main_menu_01: WPMenu
    main_menu_02: WPMenu
    main_menu_03: WPMenu
    main_menu_04: WPMenu
    main_menu_05: WPMenu
    main_menu_06: WPMenu
    main_menu_07: WPMenu
    legals_menu: WPMenu
    social_menu: WPMenu
  }
}

export const StoreContext = createContext<StoreApi<Store> | undefined>(
  undefined
)

const getInitialState = (state: Store) => {
  return createStore(devtools<Store>(() => ({ ...state })))
}

const StoreProvider = ({ children, ...props }: PropsWithChildren<Store>) => {
  const storeRef = useRef<StoreApi<Store> | undefined>(undefined)

  if (!storeRef.current) {
    storeRef.current = getInitialState(props)
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider
