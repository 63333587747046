import { useState } from 'react'
import classNames from 'classnames'

import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { useLocaleStore } from '@/store/locale'
import { LANGUAGES } from '@/constants'

const LangSelector = () => {
  const [open, setOpen] = useState(false)
  const [currentLocale, currentTranslations] = useLocaleStore((state) => [
    state.currentLocale,
    state.currentTranslations
  ])

  return (
    <>
      <button
        className="-m-xxs flex p-xxs"
        onClick={() => setOpen((open) => !open)}
      >
        <span className="flex items-baseline space-x-xxs border-b-2 border-white/30 pb-xxs">
          <Typography variant="small" className="text-white">
            IT
          </Typography>
          <span
            className={classNames(
              'flex h-[.8rem] w-[.8rem] items-center justify-center',
              'origin-center transition-all duration-300 ease-in-out-cubic',
              { 'rotate-180': open }
            )}
          >
            <SVGIcon
              symbol="arrow-down"
              className="h-[.9rem] w-[1rem] stroke-white"
            />
          </span>
        </span>
      </button>
      <div
        className={classNames(
          'absolute left-0 top-[2rem] grid h-auto w-full',
          'transition-all duration-300 ease-in-out-cubic',
          { 'grid-rows-[0fr] opacity-0': !open },
          { 'grid-rows-[1fr] opacity-100': open }
        )}
      >
        <div className="overflow-hidden">
          <ul className="flex flex-col py-[.1rem]">
            {LANGUAGES.map((lang: string) => (
              <li key={lang} className="-mx-xxs flex p-xxs">
                <a
                  href={
                    currentTranslations?.find((t) => t.locale.includes(lang))
                      ?.href || `/${lang}`
                  }
                  className={classNames('flex', {
                    'pointer-events-none': lang === currentLocale
                  })}
                >
                  <Typography
                    variant="small"
                    className={classNames('uppercase', {
                      'text-white': lang !== currentLocale,
                      'text-white/40': lang === currentLocale
                    })}
                  >
                    {lang}
                  </Typography>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default LangSelector
