import { createContext, PropsWithChildren, useMemo } from 'react'

import Theme from './Theme'

type Context = {
  fluid?: boolean
}

export const ThemeContext = createContext<Context | undefined>(undefined)

const ThemeProvider = ({
  children,
  fluid = false
}: PropsWithChildren<Context>) => {
  const contextValue = useMemo(() => ({ fluid }), [fluid])

  return (
    <ThemeContext.Provider value={contextValue}>
      <Theme fluid={fluid} />
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
