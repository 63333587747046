/* eslint-disable import/no-duplicates */
import { ReactElement, ReactNode } from 'react'
import { GoogleTagManager } from '@next/third-parties/google'
import { NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import { default as NextApp } from 'next/app'
import dynamic from 'next/dynamic'

import SVGSprites from '@/components/base/SVGSprites'
import Header from '@/components/layout/Header'
import { getPromiseFromFetch } from '@/utils/request'
import { API, GTM_ID } from '@/constants'

import '@/styles/globals.css'

import '@/base/gsap'

import { Store, StoreProvider } from '@/contexts/store'
import ThemeProvider from '@/contexts/theme/ThemeProvider'
import { TransitionProvider } from '@/contexts/transition'

const GridHelper = dynamic(() => import('@/components/base/GridHelper'), {
  ssr: false
})

const Stage = dynamic(() => import('@/webgl/Stage'), {
  ssr: false
})

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement, props: AppProps) => ReactNode
}

export default function App({
  Component,
  pageProps,
  menu,
  strings,
  yoast
}: AppProps & { Component: NextPageWithLayout } & Store) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <GoogleTagManager gtmId={GTM_ID} />
      <StoreProvider menu={menu} strings={strings} yoast={yoast}>
        <ThemeProvider fluid>
          {!process.env.production ? <GridHelper /> : null}
          <Stage />
          <Header customizations={pageProps.acf?.header || {}} />
          <TransitionProvider>
            {getLayout(<Component {...pageProps} />, pageProps)}
          </TransitionProvider>
          <SVGSprites />
        </ThemeProvider>
      </StoreProvider>
    </>
  )
}

App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext)
  const lang = appContext.router.locale

  const { data: menuData } = await getPromiseFromFetch({
    method: 'get',
    url: `${API.MENU_LOCATIONS}?lang=${lang}`
  })

  const { data: stringsData } = await getPromiseFromFetch({
    method: 'get',
    url: `${API.STRINGS}?lang=${lang}`
  })

  const { data: yoastData } = await getPromiseFromFetch({
    method: 'get',
    url: API.YOAST
  })

  return {
    ...appProps,
    strings: stringsData || {},
    yoast: yoastData?.raw || '',
    menu: Object.keys(menuData).length > 0 ? menuData : {}
  }
}
