import { find } from 'lodash'

import { Media } from '@/utils/media'

import { WPPageData } from '@/types'

export const pageHasHeroSection = (pageData: WPPageData) => {
  const builder = pageData?.acf?.builder
  const landing = pageData?.acf?.landing
  return (
    landing !== undefined ||
    find(builder, { acf_fc_layout: 'hero' }) !== undefined
  )
}

export const getPagePrecacheMedia = (pageData: WPPageData) => {
  const builder = pageData?.acf?.builder
  const hero = find(builder, { acf_fc_layout: 'hero' })

  return (
    hero !== undefined
      ? [
          {
            src: hero.image.sizes.large.url,
            type: 'image'
          }
        ]
      : []
  ) as Media[]
}
