import classNames from 'classnames'

import { useLenis, useScrollbar } from '@/contexts/lenis'

const Scrollbar = () => {
  const { lenis } = useLenis()
  const [track, thumb, needsScrollbar] = useScrollbar(lenis)

  return (
    <div
      className={classNames(
        'group fixed bottom-0 right-0 top-0 z-scrollbar w-[1.2rem] touch:hidden',
        {
          visible: needsScrollbar,
          invisible: !needsScrollbar
        }
      )}
    >
      <div ref={track} className="relative h-full w-full">
        <div
          ref={thumb}
          className={classNames(
            'absolute left-0 top-0 w-full',
            'before:absolute before:bottom-[.2rem] before:left-[.4rem] before:top-[.2rem] before:w-[.6rem] before:rounded-full before:bg-blue/90'
          )}
        />
      </div>
    </div>
  )
}

export default Scrollbar
