import { useRef, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import gsap from 'gsap'

import { Lenis } from '@/contexts/lenis'

export const useScrollbar = (lenis?: Lenis) => {
  const [isDragged, setIsDragged] = useState(false)
  const [needsScrollbar, setNeedsScrollbar] = useState(false)
  const track = useRef<HTMLDivElement>(null!)
  const thumb = useRef<HTMLDivElement>(null!)
  const thumbPointerOffset = useRef(0)
  const thumbHeight = useRef(0)
  const trackHeight = useRef(0)

  useIsomorphicLayoutEffect(() => {
    if (!lenis) return

    const update = (lenis: Lenis) => {
      if (isDragged) return
      gsap.set(thumb.current, {
        y: lenis.progress * (trackHeight.current - thumbHeight.current)
      })
    }

    lenis.on('scroll', update)

    return () => {
      lenis.off('scroll', update)
    }
  }, [lenis, isDragged])

  useIsomorphicLayoutEffect(() => {
    if (!lenis || !isDragged) return

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault()

      const thumbPosition = gsap.getProperty(thumb.current, 'y') as number
      thumbPointerOffset.current = event.clientY - thumbPosition
    }

    const handleMouseMove = (event: MouseEvent) => {
      event.preventDefault()

      const thumbMaxPosition = trackHeight.current - thumbHeight.current
      const thumbPointerPosition = event.clientY - thumbPointerOffset.current
      const thumbPosition = gsap.utils.clamp(
        0,
        thumbMaxPosition,
        thumbPointerPosition
      )

      const scrollPosition = gsap.utils.mapRange(
        0,
        thumbMaxPosition,
        0,
        lenis.limit,
        thumbPosition
      )

      gsap.set(thumb.current, { y: thumbPosition })

      lenis.scrollTo(scrollPosition, { immediate: true })
    }

    const handleMouseUp = () => {
      setIsDragged(false)
    }

    window.addEventListener('mousedown', handleMouseDown, {
      passive: false
    })
    window.addEventListener('mousemove', handleMouseMove, {
      passive: false
    })
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [lenis, isDragged])

  useIsomorphicLayoutEffect(() => {
    if (!lenis) return

    const handleMouseDown = () => {
      setIsDragged(true)
    }

    thumb.current?.addEventListener('mousedown', handleMouseDown)

    return () => {
      thumb.current?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [lenis])

  useIsomorphicLayoutEffect(() => {
    const resize = () => {
      if (!lenis) return
      const height = track.current.offsetHeight
      const wrapperHeight = lenis.dimensions.wrapper?.clientHeight || 0
      const contentHeight = lenis.dimensions.content?.scrollHeight || 0
      const ratio = Math.min(1, wrapperHeight / contentHeight)
      thumbHeight.current = height * ratio
      trackHeight.current = height

      gsap.set(thumb.current, { height: thumbHeight.current })

      setNeedsScrollbar(ratio < 1)
    }

    window.addEventListener('resize', resize)

    resize()

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [lenis])

  return [track, thumb, needsScrollbar] as const
}
