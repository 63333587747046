import { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Image from '@/components/base/Image'
// import Chatbot from '@/components/base/Chatbot'
import Logo from '@/components/base/Logo'
import Menu from '@/components/layout/Menu'
import { useLayoutStore } from '@/store/layout'

import { WPImage } from '@/types'

type HeaderType = Partial<{
  customizations: {
    alternative_logo: WPImage
  }
}>

const Header = ({ customizations }: HeaderType) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const theme = useLayoutStore((state) => state?.header?.theme || 'dark')
  const menu = useRef<HTMLDivElement>(null)
  const router = useRouter()

  useEffect(() => {
    setMenuOpen(false)
  }, [router.asPath])

  return (
    <header className="static">
      <div className="fixed left-[2rem] top-[2rem] z-logo flex h-[6rem] w-0 items-center md:left-[4rem] md:top-[4rem] lg:left-[6rem] xl:left-[10rem]">
        {customizations?.alternative_logo?.url ? (
          <div className="absolute block h-[3.5rem] w-[15.5rem]">
            <Image
              loading="eager"
              source={customizations?.alternative_logo}
              alt={customizations?.alternative_logo?.alt}
              width={customizations?.alternative_logo?.width}
              height={customizations?.alternative_logo?.height}
            />
          </div>
        ) : (
          <Link href="/" scroll={false} aria-label="Homepage">
            <Logo variant="compact" theme={menuOpen ? 'light' : theme} />
          </Link>
        )}
      </div>

      <div className="fixed right-[2rem] top-[2rem] z-hamb md:right-[4rem] md:top-[4rem] lg:right-[6rem] xl:right-[10rem]">
        <button
          id="menu-button"
          onClick={() => setMenuOpen(true)}
          className={classNames(
            'relative h-[6rem] w-[6rem] rounded-full',
            'before:absolute before:left-1/2 before:top-1/2 before:h-[.2rem] before:w-[2.4rem] before:-translate-x-1/2 before:-translate-y-[.4rem]',
            'after:absolute after:left-1/2 after:top-1/2 after:h-[.2rem] after:w-[1.6rem] after:-translate-x-1/2 after:translate-y-[.4rem]',
            'before:transition-colors before:duration-300 before:ease-out-cubic',
            'after:transition-colors after:duration-300 after:ease-out-cubic',
            'transition-colors duration-300 ease-out-cubic',
            {
              'bg-pink before:bg-white after:bg-white': theme === 'dark',
              'bg-white before:bg-pink after:bg-pink': theme === 'light'
            },
            'transition-transform duration-300 ease-out-cubic',
            {
              'scale-0': menuOpen,
              'scale-100': !menuOpen
            }
          )}
          aria-label="Open Menu"
        />

        <Transition
          nodeRef={menu}
          in={menuOpen}
          timeout={{
            enter: 0,
            exit: 500
          }}
          unmountOnExit
        >
          {(state) => (
            <div
              className={classNames(
                'absolute left-0 top-0 h-[6rem] w-[6rem] rounded-full bg-white',
                {
                  'scale-0': state === 'entering',
                  'scale-100 transition-transform duration-300 ease-out-cubic':
                    state === 'entered',
                  'scale-0 transition-transform duration-300 ease-out-cubic':
                    state === 'exiting'
                }
              )}
            >
              <button
                onClick={() => setMenuOpen(false)}
                className={classNames(
                  'absolute left-0 top-0 h-[6rem] w-[6rem] rounded-full bg-white',
                  'before:absolute before:left-1/2 before:top-1/2 before:h-[.2rem] before:w-[2.4rem] before:-translate-x-1/2 before:-translate-y-1/2 before:rotate-45 before:bg-pink',
                  'after:absolute after:left-1/2 after:top-1/2 after:h-[.2rem] after:w-[2.4rem] after:-translate-x-1/2 after:-translate-y-1/2 after:-rotate-45 after:bg-pink',
                  {
                    'before:rotate-90': state === 'entering',
                    'before:rotate-45 before:transition-transform before:duration-300 before:ease-out-cubic':
                      state === 'entered',
                    'before:rotate-90 before:transition-transform before:duration-300 before:ease-out-cubic':
                      state === 'exiting'
                  },
                  {
                    'after:rotate-0': state === 'entering',
                    'after:-rotate-45 after:transition-transform after:duration-300 after:ease-out-cubic':
                      state === 'entered',
                    'after:rotate-0 after:transition-transform after:duration-300 after:ease-out-cubic':
                      state === 'exiting'
                  }
                )}
                aria-label="Close Menu"
              />
            </div>
          )}
        </Transition>
      </div>

      <Transition
        nodeRef={menu}
        in={menuOpen}
        timeout={{
          enter: 0,
          exit: 500
        }}
        unmountOnExit
      >
        {(state) => (
          <Menu ref={menu} state={state} onClose={() => setMenuOpen(false)} />
        )}
      </Transition>

      {/* <Chatbot onClick={handleChatbotRequest} /> */}
    </header>
  )
}

export default Header
