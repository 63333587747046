import axios from 'axios'

import { API_BASE_URL, FRONT_BASE_URL, KEY } from '@/constants'

const token = `${KEY.GF_CONSUMER_KEY}:${KEY.GF_CONSUMER_SECRET}`
const encodedToken = Buffer.from(token).toString('base64')
const headers = { Authorization: `Basic ${encodedToken}` }

const axiosServer = axios.create({ baseURL: API_BASE_URL, headers })
const axiosClient = axios.create({ baseURL: FRONT_BASE_URL })

axiosClient.defaults.headers.common['Env'] =
  process.env.NODE_ENV === 'development' ? 'development' : 'production'

export { axiosClient, axiosServer }
